import React, { useState, useEffect } from 'react';
import CrudTable from '../table/crudtable'
import { deviceService, accessTagService, deviceModelService, authService, sensorService, environmentService } from '../../_services'
import DeviceForm from './deviceform.component'
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

function Device() {

	const [accessTags, setAccessTags] = useState([])
	const [sensors, setSensors] = useState([])
	const {t} = useTranslation('common');

	const initialState = {
		accessTag: '',
		name: '',
		description: '',
		ref: '',
		networkType: 'LORAWAN',
		network: '',
		params: {},
		deviceModel: '',
		model: ''
	}

	useEffect(() => {
		console.log('useEffect')
		if (accessTags.length == 0) {
			accessTagService.getAll().then(accessTags => {
                setAccessTags(accessTags)
            })
		}
		if (sensors.length == 0) {
			sensorService.getAll().then(sensors => {
                setSensors(sensors)
            })
		}
    }, [])

    const accessTagFormatter = function(cell) {
        var accessTag = accessTags.find((accessTag) => accessTag.id == cell)
        if (accessTag) {
			return accessTag.name
        }
        return cell
    }

    const lastResultFormatter = function(cell) {
		if (cell) {
			var params = ''
			for (const [key, value] of Object.entries(cell.params)) {
	            params += `${key.charAt(0).toUpperCase() + key.slice(1)}: ${value} `
	        }
	        return params
		}
		return '-'
	}

	const sensorFormatter = function(cell, row) {
		var device = row
		var sensorNames = []
		for (var i = 0; i < sensors.length; i++) {
			var sensor = sensors[i]
			if (sensor.deviceRefs.indexOf(device.ref) >= 0) {
				sensorNames.push(sensor.name)
			}
		}
		if (sensorNames.length > 1) {
			return <span><FontAwesomeIcon icon={faExclamationTriangle} style={{color: 'red'}}/> {sensorNames.join(', ')}</span>
		} else if (sensorNames.length == 1) {
			return <span>{sensorNames.join(', ')}</span>
		}
        return '-'
    }

    const isHidden = () => {
		return environmentService.loadEnvironment() == 'production_wexnet'
    }

	const columns = [
		{
			dataField: 'name',
			text: t('device.columns.name'),
			sort: true
		},
		{
            dataField: 'ref',
            text: t('device.columns.ref'),
            sort: true
        },
        {
			dataField: 'deviceModelName',
			text: t('device.columns.model'),
			formatter: (col, row) => col + (row.model ? ' (' + row.model + ')' : ''),
			sort: true
		},
		{
			dataField: 'networkType',
			text: t('device.columns.networktype'),
			sort: true,
			hidden: isHidden()
		},
		{
			dataField: 'network',
			text: t('device.columns.network'),
			sort: true
		},
		{
			dataField: 'accessTag',
			text: t('device.columns.accesstag'),
			formatter: accessTagFormatter,
			sort: true
		},
		{
			dataField: 'lastResult',
			text: t('device.columns.lastresult'),
			sort: true,
			formatter: lastResultFormatter
		},
		{
			dataField: 'sensors',
			text: t('device.columns.sensors'),
			formatter: sensorFormatter
		}
	]

	return (
		<div>
			<h2>
				{t('device.label')}
			</h2>
			{ accessTags.length > 0 && sensors.length > 0 &&
				<CrudTable
					service={deviceService}
					columns={columns}
					initialState={initialState}
					form={<DeviceForm />}
					sort={{dataField: 'name', order: 'asc'}}
					creatable={authService.hasRoles(["ROLE_DEVICE_WRITE"])}
					editable={authService.hasRoles(["ROLE_DEVICE_WRITE"])}
					deletable={authService.hasRoles(["ROLE_DEVICE_WRITE"])}
				/>
			}
		</div>
	)
}

export default Device;